import { useQuery } from "@apollo/client";
import { SettingsQuery } from "../../lib/query/settings";
import Photo from "./Photo";

function PhotosContainer() {
  const { data, loading, error } = useQuery(SettingsQuery);
  console.log(data);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const settings = data.settings[0];

  return (
    <div className="photos__container">
      {settings.imageGallery.images.map((photo) => (
        <Photo key={photo.id} photo={photo} />
      ))}
    </div>
  );
}

export default PhotosContainer;
