import "./Route.scss";

export default function RouteContainer() {
  return (
    <div className="route__container">
      {/* TODO: Create own component */}
      <div className="route">
        <iframe
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDuUNapaWHyKTE3MCM8GTiFLTvwwf6xK7I&q=Cafe+de+Toerist,Spaarndam+Nederland&region=NL&language=nl">
        </iframe>
      </div>
    </div>
  )
}