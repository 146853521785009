import './Products.scss';
import Icon from '../Icon';
import { VEGAN_ICON_COLOR, VEGETARIAN_ICON_COLOR } from "constants/colors";

const euro = (amount) => 
  amount.toLocaleString('nl-NL', {
    style: 'currency', 
    currency: 'EUR', 
    minimumFractionDigits: 2 
  });

export function Product({ product }) {
  let description;
  let icon;

  if (product.description) {
    description = (<p className="product__description">{product.description}</p>);
  }

  if (product.isVegetarian) {
    icon = <Icon name="leaf" title="Vegetarisch" color={VEGETARIAN_ICON_COLOR} />;
  }

  if  (product.isVegan) {
    icon = <Icon name="leaf" title="Vegan" color={VEGAN_ICON_COLOR} />;
  }

return (
    <li className="product" key={product.id}>
      <div className="product__title__container">
        <span className="product__title">{product.title}</span>
        {icon}
      </div>
      {description}
      <span className="product__price">{euro(product.price)}</span>
    </li>
  )
}

export default function Products({ products }) {
  return (
    <ul className="products">
      {products.map(product => {
        return (
          <Product product={product} key={product.id}/>
        )
      })}
    </ul>
  )
}