import { useQuery } from "@apollo/client";
import { MenuBySlugQuery } from "../../lib/query/menu";
import Menu from "./Menu";

function MenuContainer({ params: { slug } }) {
  const { data, loading, error } = useQuery(MenuBySlugQuery, {
    variables: { slug },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="menu__container">
      {data.menus.map((menu) => (
        <Menu key={menu.id} menu={menu} />
      ))}
    </div>
  );
}

export default MenuContainer;
