import './Modal.scss';
import ReactModal from 'react-modal';

function Modal({ open, afterOpenModal, closeModal, style, title, children }) {
  return (
    <ReactModal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={style}
        contentLabel={title}
      >
        <button onClick={closeModal} className="modal__close">Sluiten</button>
        <div className="modal__content">
          {children}
        </div>
      </ReactModal>
  );
}

export default Modal;