import { gql } from "@apollo/client";

export const MessagesQuery = gql`
  query Messages {
    messages(orderBy: publishedAt_DESC) {
      id
      title
      description {
        html
      }
      buttonText
      publishedAt
      menu {
        id
        slug
      }
    }
  }
`;
