import { gql } from "@apollo/client";

const JobData = `
  title
  description {
    html
    text
  }
`;

export const JobsQuery = gql`
  query Jobs {
    jobs {
      ${JobData}
    }
  }
`;
