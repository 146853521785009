import { useQuery } from "@apollo/client";
import { SettingsQuery } from "../../lib/query/settings";
import Sidebar from "./Sidebar";

export default function SidebarContainer(props) {
  const settings = useQuery(SettingsQuery);

  if (settings.loading) return <p>Loading...</p>;
  if (settings.error) return <p>Error :(</p>;

  return <Sidebar settings={settings.data.settings[0]} {...props}/>;
}
