import './Photo.scss';
import { useState } from 'react';
import Modal from '../modal/Modal';

function Photo({ photo }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (window.innerWidth < 1024) return;
    setIsOpen(true);
  }

  return (
    <>
    <img onClick={handleClick} className='photo' src={photo.url}/>
    <Modal open={isOpen} closeModal={() => setIsOpen(false)}>
      <img className='photo' src={photo.url}/>
    </Modal>
    </>
  )
}

export default Photo;