import { useQuery } from "@apollo/client";
import { MessagesQuery } from "../../lib/query/message";
import Messages from './Messages';

function MessagesContainer() {
  const { data, loading, error } = useQuery(MessagesQuery);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="messages__container">
      <Messages messages={data.messages}/>
    </div>
  );
}

export default MessagesContainer;
