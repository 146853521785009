import "./Messages.scss";
import { Link } from 'wouter';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

export function Message({ message }) {
  let button;
  let publishedAt = new Date(message.publishedAt);

  if (message.menu && message.buttonText) {
    button = (
      <Link href={`/menu/${message.menu.slug}`}>
        <a className="button button--primary">{message.buttonText}</a>
      </Link>
    );
  }

  return (
    <div className="message">
      <h2 className="message__title">{message.title}</h2>
      <span className="message__publishedAt">Geplaatst op {format(publishedAt, 'dd MMMM yyyy', { locale: nl })}</span>
      <p className="message__description" dangerouslySetInnerHTML={{ __html: message.description?.html }} />
      {button}
    </div>
  )
}

export default function Messages({ messages }) {
  return (
    <div className="messages">
      {messages.map(message => {
        return (
          <Message message={message} key={message.id}/>
        )
      })}
    </div>
  )
}