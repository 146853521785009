import './Menu.scss';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Products from 'components/product/Products';
import Icon from 'components/Icon';
import { VEGAN_ICON_COLOR, VEGETARIAN_ICON_COLOR } from 'constants/colors';

function Menu({ menu }) {
  const container = useRef();

  const handleClick = (id) => {
    const mobile = window.innerWidth <= 1024;
    const section = mobile ? document.querySelector('.App') : document.querySelector('section');
    const item = section.querySelector(`#${id}`);
    section.scrollTop = mobile ? item.offsetTop - 25 : item.offsetTop - 88;
  };
  
  return (
    <div className="menu" ref={container}>
      <Helmet>
        <title>Cafe de Toerist | {menu.title}</title>
        <meta name="description" content={menu.description?.text} />
      </Helmet>

      <h1 className="menu__title">{menu.title}</h1>
      <p className="menu__description" dangerouslySetInnerHTML={{ __html: menu.description?.html}}/>
      
      <hr/>

      {menu.submenus.map(submenu => (
        <button className='submenu__link' onClick={() => handleClick(submenu.id)}>{submenu.displayTitle}</button>
      ))}

      {menu.submenus.map(submenu => {
        let extraInfo;

        if (submenu.extraInfo?.text) {
          extraInfo = <span className="submenu__extra-info">{submenu.extraInfo.text}</span>
        }

        return (
          <div id={submenu.id} key={submenu.id} className="submenu">
            <h3>{submenu.displayTitle}</h3>
            <Products products={submenu.products}/>
            {extraInfo}
          </div>
        )
      })}
      
      <div className="legend">
        <span className="legend__item">
          <Icon name="leaf" color={VEGETARIAN_ICON_COLOR}/> = Vegetarisch
        </span>
        <span className="legend__item">
          <Icon name="leaf" color={VEGAN_ICON_COLOR}/> = Vegan
        </span>
      </div>
    </div>
  );
}

export default Menu;
