import { gql } from "@apollo/client";

const MenuData = `
  id
  title
  slug
  description {
    html
    text
  }
  submenus {
    id
    displayTitle
    extraInfo {
      text
    }
    products {
      id
      title
      description
      price
      isVegan
      isVegetarian
    }
  }
`;

export const MenusQuery = gql`
  query Menus {
    menus(where: { visible: true }) {
      ${MenuData}
    }
  }
`;

export const MenuSlugsQuery = gql`
  query MenuSlugs {
    menus(where: { visible: true }) {
      id
      title
      slug
    }
  }
`;

export const MenuBySlugQuery = gql`
  query MenuBySlug($slug: String!) {
    menus(where: { visible: true, slug: $slug }) {
      ${MenuData}
    }
  }
`;
