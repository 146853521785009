import "./App.scss";
import { Route, useLocation } from "wouter";
import SidebarContainer from "./sidebar/Sidebar.container";
import NavigationContainer from './navigation/Navigation.container';
import HomeContainer from './home/Home.container';
import MenuContainer from "./menu/Menu.container";
import JobContainer from "./jobs/Jobs.container";
import PhotosContainer from "./photos/Photos.container";
import RouteContainer from './route/Route.container';

function App(props) {
  const [location] = useLocation();

  document.getElementById("root").classList.toggle('has-bg', location === '/');
  
  const sections = {
    '/route': 'section--route',
  };

  const sectionClass = sections[location] ?? '';

  return (
    <div className={`App `}>
      <SidebarContainer/>

      <div className={`section__wrapper ${sectionClass}`}>
        <NavigationContainer/>

        <section className={`section ${sectionClass}`}>
          <Route path="/" component={HomeContainer}/>
          <Route path="/route" component={RouteContainer}/>
          <Route path="/menu/:slug" component={MenuContainer} />
          <Route path="/vacatures" component={JobContainer} />
          <Route path="/fotos" component={PhotosContainer} />
        </section>
      </div>
    </div>
  );
}

export default App;
