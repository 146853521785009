import { Helmet } from 'react-helmet';
import { useQuery } from "@apollo/client";
import { JobsQuery } from "../../lib/query/job";
import Job from "./Job";

function JobsContainer({ params: { slug } }) {
  const { data, loading, error } = useQuery(JobsQuery);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="jobs__container">
      <Helmet>
        <title>Cafe de Toerist | Vacatures</title>
        <meta name="description" content="We zijn weer volledig open en zijn op zoek naar nieuwe collega’s die ons team kunnen versterken!" />
      </Helmet>

      <h1>Vacatures</h1>
      <hr/>

      {data.jobs.length === 0 && (
        <p>Helaas zijn er op dit moment geen vacatures!</p>
      )}

      {data.jobs.map((job) => (
        <Job key={job.id} job={job} />
      ))}
    </div>
  );
}

export default JobsContainer;
