import Table, { Row, Column } from '../shared/table/Table'; 

export default function OpeningTimes({ data }) {
  const days = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"];

  return (
    <div className="table__container">
      <Table title="Openingstijden">
        {Object.keys(data).map(key => {
          return (
            <Row key={key}>
              <Column>{key}</Column>
              <Column align="right">{data[key]}</Column>
            </Row>
          )
        }).sort((a, b) => {
          return days.indexOf(a.key) - days.indexOf(b.key);
        })}
      </Table>
    </div>
  )
}