import "./Table.scss";

export function Column({ children, ...restProps }) {
  return (
    <td className="table__column" {...restProps}>{children}</td>
  )
}

export function Row({ children, ...restProps }) {
  return (
    <tr className="table__row" {...restProps}>
      {children}
    </tr>
  )
}

export default function Table({ title, children }) {
  let header;

  if (title) {
    header =
      <thead className="table__header">
        <Row>
          <Column colSpan="2">{title}</Column>
        </Row>
      </thead>;
  }

  return (
    <div className="table__container">
      <table className="table">
        {header}
        <tbody className="table__body">
          {children}
        </tbody>
      </table>
    </div>
  )
}