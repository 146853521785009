import "./Sidebar.scss";
import { useState } from 'react';
import { Link } from 'wouter';
import Icon from '../Icon';
import OpeningTimes from '../opening-times/OpeningTimes';

export default function Sidebar({ settings }) {
  const [timesActive, setTimesActive] = useState(false);

  const toggle = () => {
    setTimesActive(current => !current);
  };

  const hiddenClass = !timesActive ? "is-hidden" : "";

  return (
    <nav className={`sidebar`}>
      <ul className="sidebar__list sidebar__list--logo">
        <li className="sidebar__item sidebar__item--logo">
          <Link href="/">
            <a className="sidebar__link">De&nbsp;Toerist</a>
          </Link>
        </li>
      </ul>

      <div className="sidebar__list__wrapper">
        <Icon name="location-pin" theme="primary" size="large" to="/route"/>

        <ul className="sidebar__list sidebar__list--info">
          <li className="sidebar__item sidebar__item--info">Spaarndammerdijk 98</li>
          <li className="sidebar__item sidebar__item--info">2063 JX Spaarndam</li>
          <li className="sidebar__item sidebar__item--info sidebar__item--phone">023 537 6000</li>
        </ul>
      </div>


      <div className={`sidebar__table__container ${hiddenClass}`}>
        <div className="sidebar__table__toggle" onClick={toggle}>
          Openingstijden <Icon name="rounded-down"/>
        </div>
        <OpeningTimes data={settings.openingTimes}/>
      </div>
    </nav>
  )
}