import './Job.scss';

function Job({ job }) {

  return (
    <div className="job">
      <h2>{job.title}</h2>
      <p dangerouslySetInnerHTML={{ __html: job.description.html }}></p>
    </div>
  );
}

export default Job;
