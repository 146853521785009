import { useQuery } from "@apollo/client";
import { MenuSlugsQuery } from "../../lib/query/menu";
import Navigation from "./Navigation";

export default function NavigationContainer(props) {
  const menus = useQuery(MenuSlugsQuery);

  if (menus.loading) return <p>Loading...</p>;
  if (menus.error) return <p>Error :(</p>;

  return <Navigation menus={menus.data.menus} {...props} />;
}
