import { gql } from "@apollo/client";

export const SettingsQuery = gql`
  query Settings {
    settings(first: 1) {
      openingTimes
      imageGallery {
        images {
          id
          url
        }
      }
    }
  }
`;
