import { Helmet } from "react-helmet";
import Home from "./Home";

export default function HomeContainer() {
  return (
    <div className="home__container">
      <Helmet>
        <title>Cafe de Toerist | Home</title>
      </Helmet>

      <Home />
    </div>
  );
}
