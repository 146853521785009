import "./Navigation.scss";
import { Link } from "wouter";

export default function Navigation({ menus, onSidebarIconClicked }) {
  return (
    <div className="navigation">
      <ul className="navigation__list navigation__list--menus">
        {menus.map(menu => {
          return (
            <li className="navigation__item navigation__item--menu" key={menu.id}>
              <Link to={`/menu/${menu.slug}`}>
                <a className="navigation__item__link">{menu.title}</a>
              </Link>
            </li>
          )
        })}
        <li className="navigation__item navigation__item--menu">
          <Link to={`/vacatures`}>
            <a className="navigation__item__link">Vacatures</a>
          </Link>
        </li>
        <li className="navigation__item navigation__item--menu">
          <Link to={`/fotos`}>
            <a className="navigation__item__link">Fotos</a>
          </Link>
        </li>
      </ul>
    </div>
  )
}