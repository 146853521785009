import "./Icon.scss";
import { useLocation } from "wouter";

export default function Icon({ name, to, theme = "default", size = "normal", title, color, ...rest }) {
  const [_, setLocation] = useLocation();

  const themeClass = "theme--" + theme;
  const buttonClass = to ? "icon__wrapper--button" : "";
  const sizeClass = "size--" + size;

  const props = {
    ...(to && {
      onClick: () => setLocation(to)
    }),
    ...(title && {
      title: title
    }),
    className: `icon__wrapper ${buttonClass} ${themeClass} ${sizeClass}`
  };

  return (
    <span {...props} {...rest}>
      <span className={`icon icofont icofont-${name}`} style={{ color }}/>
    </span>
  )
}